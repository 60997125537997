import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'output']
  static values = { showIf: String }

  declare readonly inputTargets: [HTMLInputElement]
  declare readonly outputTargets: [HTMLInputElement]

  connect(): void {
    this.toggle()
  }

  toggle(): void {
    this.outputTargets.forEach(element => {
      let inputElement = null
      if (element.dataset.scope == null) {
        inputElement = this.inputTargets[0]
      } else {
        inputElement = this.inputTargets.find(obj => obj.dataset.scope === element.dataset.scope)
      }
      if (inputElement == null) return

      const inputValue = this.inputTargetValue(inputElement)
      if (element.dataset.showIfValue != null && inputValue === element.dataset.showIfValue) {
        element.hidden = false
        element.classList.remove('hidden')
      } else {
        element.hidden = true
        element.classList.add('hidden')
      }
    })
  }

  private inputTargetValue(element: HTMLInputElement): string {
    if (element.type === 'checkbox') {
      return (+element.checked).toString()
    } else {
      return element.value
    }
  }
}
